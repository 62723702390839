<template>
  <div v-if="props.context.isLabelVisible" class="text-xs md:ml-2 mb-1 whitespace-nowrap md:truncate">
    {{ `${t('profile.country_code')} *` }}
  </div>
  <Menu as="div" class="relative w-full">
    <div>
      <MenuButton
        class="flex w-full items-center rounded-lg justify-between bg-white px-4 py-3.5 border border-gray-300"
        :class="props.context.disabled && 'opacity-40 cursor-not-allowed'"
        :disabled="props.context.disabled"
      >
        <div class="md:truncate">
          {{ selectedCountry?.id }}
        </div>
        <div class="flex">
          <FormKitIcon icon="ChevronDownIcon" class="md:-mr-1 md:ml-2 ml-3 h-4 w-4" aria-hidden="true" />
        </div>
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        as="div"
        class="absolute sm:left-0 -left-6 z-10 mt-1 h-96 overflow-y-scroll origin-top-right rounded-2xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none"
        :style="{ width: '320px' }"
      >
        <div class="p-3">
          <MenuItem
            class="flex text-sm justify-between px-5 py-1.5 border-b border-gray-400 hover:bg-green-200 cursor-pointer"
            as="div"
            v-for="option in options"
            :key="option.value"
            @click="handleSelect(option)"
          >
            <div>
              {{ option.label }}
            </div>
            <div class="text-gray-500">
              {{ option.value }}
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { computed, ref } from 'vue';
import { phoneCodes } from '../../stores/constants';
import { useTypedI18n } from '../../plugins/i18nPlugin';
import { FormKitIcon } from '@formkit/vue';
import { FormKitNode } from '@formkit/core';
import { onMounted } from 'vue';

const { t } = useTypedI18n();

export type PhoneCode = { label: string; value: string; abbreviation: string };
export type Country = {
  code: string;
  id: string;
};

type FormkitContext = {
  country: Country;
  disabled?: boolean;
  isLabelVisible?: boolean;
  node: FormKitNode;
};

const props = defineProps<{
  context: FormkitContext;
}>();

const options = ref(phoneCodes);

const selectedCountry = computed(() => {
  if (props.context.country.code && props.context.country.id) {
    return props.context.country;
  }
  const defaultPhoneCode = props.context.country.code
    ? phoneCodes.find(pc => pc.value === props.context.country.code)
    : phoneCodes.find(pc => pc.abbreviation === 'US');

  return {
    code: defaultPhoneCode?.value || '+1',
    id: defaultPhoneCode?.abbreviation || 'US',
  };
});

const handleSelect = (option: PhoneCode) => {
  const tmpCountry = { id: option.abbreviation, code: option.value };
  props.context.node.input(tmpCountry);
};

onMounted(() => {
  if (!props.context.country.code || !props.context.country.id) {
    handleSelect(phoneCodes.find(pc => pc.abbreviation === 'US')!);
  }
});
</script>
