<script setup lang="ts">
import { useUserStore } from './stores/userStore';
import { computed, inject } from 'vue';
import { FormKitConfig } from '@formkit/core';
import { useUserflow } from './services/useUserflow';
import { useHotjar } from './services/useHotjar';
import { useRoute } from 'vue-router';
import LoadingComponent from '@/components/LoadingComponent.vue';

const userStore = useUserStore();
const hotjar = useHotjar();
const userflow = useUserflow();
const route = useRoute();

const isLoading = computed(() => route.matched.length === 0);

const formkitConfig = inject<FormKitConfig>(Symbol.for('FormKitConfig'));

userflow.init();
hotjar.init();

// A subscribe is needed because formkitConfig is only accessible inside a vue file
// TODO understand if we need to check if formkit by default take the browser language
userStore.$subscribe((mutation, state) => {
  const currentLanguage = state.loggedInInfo?.user?.accountLanguage;
  if (formkitConfig && currentLanguage && currentLanguage != formkitConfig.locale) {
    if (currentLanguage === 'mx') formkitConfig.locale = 'es';
    else formkitConfig.locale = currentLanguage;
  }
});
</script>

<template>
  <RouterView />
  <LoadingComponent v-if="isLoading" scope="fixed" />
</template>
