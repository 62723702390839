import userflow from 'userflow.js';
import { UserDTO } from '../services/userDataService';
import { AccountLanguage } from './useLanguageService';
import { envs } from '../env';

const VITE_USERFLOW_TOKEN = envs.VITE_USERFLOW_TOKEN;
const VITE_LINKMATE_PREFIX = envs.VITE_LINKMATE_PREFIX;

let hasBeenInitialized = false;

export function useUserflow() {
  const init = () => {
    callOrSkip(() => interalInit());
  };

  const openHelp = (language: AccountLanguage | 'onboarding') => {
    const helpUserflowHelpMap: Record<Exclude<AccountLanguage, undefined> | 'onboarding', string> = {
      en: 'f7f7608c-a942-45df-b548-887461741cbc',
      de: 'a5d539c5-46b8-403d-8213-ac7c6cb7236a',
      es: '617a3865-0bec-43c6-ab84-4800feef4777',
      mx: '617a3865-0bec-43c6-ab84-4800feef4777',
      it: 'f7f7608c-a942-45df-b548-887461741cbc',
      sv: 'f7f7608c-a942-45df-b548-887461741cbc',
      nl: 'f7f7608c-a942-45df-b548-887461741cbc',
      fr: 'f7f7608c-a942-45df-b548-887461741cbc',
      pt: 'f7f7608c-a942-45df-b548-887461741cbc',
      ja: 'f7f7608c-a942-45df-b548-887461741cbc',
      ro: 'f7f7608c-a942-45df-b548-887461741cbc',
      onboarding: 'e5f76871-8eec-4d1a-83a2-b4f11923b293',
    };
    const userflowId = language ? helpUserflowHelpMap[language] : helpUserflowHelpMap['en'];
    callOrSkip(() => userflow.start(userflowId));
  };

  const openWelcome = (language: AccountLanguage | undefined) => {
    const languageUserflowWelcomMap: Record<Exclude<AccountLanguage, undefined>, string> = {
      en: '56ee08ca-63b1-4f8d-bb56-aff41f663b23',
      sv: '56ee08ca-63b1-4f8d-bb56-aff41f663b23',
      de: '4f2c49b6-44bb-4b01-a228-93e4899574fb',
      es: '4745bcd7-7b7a-40d3-8b37-ca7c65af6978',
      mx: '4745bcd7-7b7a-40d3-8b37-ca7c65af6978',
      it: 'a43d0101-02a5-43e0-9903-f9eacb4f40fd',
      nl: '3943cc0f-fba2-4e4b-8eed-e3650f6bfeff',
      fr: '916ee8c2-909f-4a78-9e8e-75a638cb5a7f',
      pt: '52a8c8d3-27c7-4871-bca2-8de886fed895',
      ja: '56ee08ca-63b1-4f8d-bb56-aff41f663b23',
      ro: '56ee08ca-63b1-4f8d-bb56-aff41f663b23',
    };
    const userflowId = language ? languageUserflowWelcomMap[language] : languageUserflowWelcomMap['en'];
    callOrSkip(() => userflow.start(userflowId));
  };

  const identify = (language: AccountLanguage, user: UserDTO) => {
    callOrSkip(() =>
      userflow.identify(user.id.toString(), {
        name: user.firstName,
        email: user.email,
        signed_up_at: user.createdAt,
        locale_code: language,
      })
    );
  };

  const callOrSkip = (callback: () => void) => {
    if (window.sessionStorage.getItem('isTesting') === 'true') {
      console.info('SKIPPING userflow because TESTING');
      return;
    }
    if (window.location.href.startsWith(VITE_LINKMATE_PREFIX)) {
      console.info('SKIPPING userflow because in linkmate');
      return;
    }
    interalInit();
    callback();
  };

  const interalInit = () => {
    if (!hasBeenInitialized) {
      userflow.init(VITE_USERFLOW_TOKEN);
      hasBeenInitialized = true;
    }
  };

  return {
    openHelp,
    identify,
    init,
    openWelcome,
  };
}
