import { useUserStore } from '@/stores/userStore';
import qs from 'qs';
import http, { StrapiDTO, StrapiResponseDTO } from './axiosClient';
import useI18nUtils from './useI18nUtils';
import { tenantsArray } from '@/env';
import { useHandleError } from './logErrors';

export interface TenantDTO {
  tenant: Tenant;
  createdAt?: Date;
  updatedAt?: Date;
}
export type TenantWithId = TenantDTO & { id: number };

export type Tenant = (typeof tenantsArray)[number];

let currentTenant: Tenant | undefined;

export function useTenantService() {
  const isValidTenant = (tenant?: string): tenant is Tenant => {
    const availableTenants: readonly string[] = tenantsArray;
    return !!tenant && availableTenants.includes(tenant);
  };

  const getTenantFromRoute = () => {
    const parts = window.location.pathname.split('/').filter(part => part);
    const tenant = parts[0];
    if (!isValidTenant(tenant)) return;
    return tenant;
  };

  const getTenantName = () => {
    return currentTenant || getTenantFromRoute();
  };

  const setTenant = (tenant: string) => {
    if (!isValidTenant(tenant)) throw new Error('Invalid tenant');
    currentTenant = tenant;
    useI18nUtils().setLanguage(useUserStore().loggedInInfo?.user?.accountLanguage, tenant);
  };

  const isCorrectTenant = (tenantName?: string) => {
    return tenantName === getTenantName();
  };

  const getTenantId = () => {
    const userStore = useUserStore();
    if (!userStore.loggedInInfo?.user?.tenant) {
      useHandleError().throwError('Tenant not found');
      throw new Error('Tenant not found');
    }
    return userStore.loggedInInfo?.user?.tenant.id;
  };

  const getTenantFromApi = async (tenant: Tenant) => {
    const query = qs.stringify(
      {
        filters: {
          tenant: { $eq: tenant },
        },
      },
      { encodeValuesOnly: true }
    );
    try {
      const resp = await http.get<StrapiResponseDTO<StrapiDTO<TenantDTO>[]>>(`api/tenants?${query}`);
      if (!resp.data.data.length) return;
      return resp.data.data[0];
    } catch (error) {
      return;
    }
  };

  const getTenants = async () => {
    try {
      const resp = await http.get<StrapiResponseDTO<StrapiDTO<TenantDTO>[]>>(`api/tenants`);
      return resp.data.data;
    } catch (error) {
      return [];
    }
  };

  return {
    isCorrectTenant,
    getTenantName,
    setTenant,
    getTenantFromRoute,
    getTenantFromApi,
    getTenantId,
    getTenants,
  };
}
