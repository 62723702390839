import { FileObject } from '../components/UploadFileComponent.vue';
import http, { useAxiosUtils } from './axiosClient';
import { LinkMateWithId } from './linkMateService';
import { LandbotLinkLanguage, AccountLanguage } from './useLanguageService';
import { useUtilsService } from './utilsService';
import qs from 'qs';
import { TenantWithId } from '../services/tenantService';
import { SubscriptionsStatus } from './useStripeServices';

export interface UserDTO {
  id: number;
  username: string;
  firstName: string;
  email: string;
  lastName: string;
  createdAt: string;
  tenant: TenantWithId;
  blocked?: boolean;
  confirmed?: boolean;
  facebookLink?: string;
  imageLink?: ImageLink;
  phoneNumber?: string;
  provider?: string;
  shopLink?: string;
  updatedAt?: string;
  bundleGood?: string;
  bundleBetter?: string;
  bundleBest?: string;
  countryCode?: string;
  countryId?: string;
  linkLanguage?: LandbotLinkLanguage;
  accountLanguage?: AccountLanguage;
  currentMonthClicks?: number;
  lastMonthClicks?: number;
  lastClickTimestamp?: Date;
  profileTree?: LinkMateWithId;
  dmoChallengeChosen?: string | null;
  role: Role;
  hasBeenWelcomed?: boolean;
  stripeCustomerId?: string;
  user_library_setting?: { id: number };
  subscriptionStatus?: SubscriptionsStatus;
}

export interface Role {
  createdAt: null;
  description: string;
  id: number;
  name: 'Admin' | 'Authenticated' | 'Public';
  type: 'admin' | 'authenticated' | 'public';
  updatedAt: Date;
}

export type UserDTOOut = Omit<UserDTO, 'email' | 'id' | 'username' | 'firstName' | 'lastName' | 'role' | 'tenant' | 'createdAt'>;

export type UserDTOOutProfile = Omit<UserDTO, 'id' | 'username' | 'role' | 'tenant' | 'createdAt'>;

export interface ImageLinkSizes {
  ext?: string;
  hash?: string;
  height?: number;
  mime?: string;
  name?: string;
  path?: string;
  size?: number;
  url?: string;
  width?: number;
}
export interface ImageLink extends FileObject {
  alternativeText?: string;
  caption?: string;
  createdAt?: string;
  ext?: string;
  formats?: {
    large?: ImageLinkSizes;
    medium?: ImageLinkSizes;
    small?: ImageLinkSizes;
    thumbnail?: ImageLinkSizes;
  };
  url?: string;
  hash?: string;
  height?: number;
  mime?: string;
  previewUrl?: string;
  provider?: string;
  provider_metadata?: string;
  size?: number;
  updatedAt?: string;
  width?: number;
}
export interface LanguageHandlerObject<T = AccountLanguage | LandbotLinkLanguage> {
  icon: string;
  label: string;
  value: T;
}

let lastUpdateTimestamp = 0;
const INTERVAL = 60 * 60 * 1000;
const userFromLinkMateNickNameCache: Record<string, UserDTO> = {};

export function useUserService() {
  const { getHeaders, getApiToken } = useAxiosUtils();
  const { trimAndSanitizeLink, trimPhoneNumer } = useUtilsService();

  function getUser() {
    const query = qs.stringify(
      {
        populate: {
          imageLink: true,
          role: true,
          tenant: true,
          user_library_setting: {
            fields: ['id'],
          },
          profileTree: {
            populate: ['image'],
          },
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );
    return http.get<UserDTO>(`/api/users/me?${query}`, getHeaders());
  }

  function getUserByIdWithAPIKey(id: number) {
    const query = qs.stringify(
      {
        fields: ['subscriptionStatus', 'createdAt'],
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );
    return http.get<Pick<UserDTO, 'id' | 'subscriptionStatus' | 'createdAt'>>(`/api/users/${id}?${query}`, getApiToken());
  }

  function updateUserInfo(id: number, data: UserDTOOut) {
    data.phoneNumber = trimPhoneNumer(data.phoneNumber);
    data.facebookLink = trimAndSanitizeLink(data.facebookLink, true);
    data.shopLink = trimAndSanitizeLink(data.shopLink, true);
    data.bundleGood = trimAndSanitizeLink(data.bundleGood);
    data.bundleBetter = trimAndSanitizeLink(data.bundleBetter);
    data.bundleBest = trimAndSanitizeLink(data.bundleBest);
    return http.put<UserDTO>(`/api/users/${id}`, data, getHeaders());
  }

  const getUserFromLinkMateNickName = async (nickname: string) => {
    if (userFromLinkMateNickNameCache[nickname]) {
      return userFromLinkMateNickNameCache[nickname];
    }
    const query = qs.stringify(
      {
        filters: {
          profileTree: {
            linkName: nickname,
          },
        },
        populate: ['profileTree', 'tenant'],
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );
    const res = await http.get<UserDTO[]>(`/api/users?${query}`, getApiToken());
    if (res.data.length === 0) {
      return;
    }
    userFromLinkMateNickNameCache[nickname] = res.data[0];
    return res.data[0];
  };

  function isUpdateNeeded() {
    const now = new Date().getTime();
    return now - lastUpdateTimestamp > INTERVAL;
  }

  function resetLastUpdateTimestamp(when?: Date) {
    if (when) {
      lastUpdateTimestamp = when.getTime();
      return;
    }
    lastUpdateTimestamp = 0;
  }

  return {
    isUpdateNeeded,
    resetLastUpdateTimestamp,
    getUser,
    updateUserInfo,
    getUserByIdWithAPIKey,
    getUserFromLinkMateNickName,
  };
}
