import { computed, ref } from 'vue';
import http, { StrapiDTO, StrapiResponseDTO, useAxiosUtils } from './axiosClient';
import { useUserStore } from '../stores/userStore';
import qs from 'qs';
import { useTenantService } from './tenantService';

export const unicornProductPageLanguages = ['de', 'en', 'es', 'it', 'sv'] as const;
export type UnicornProductPageLanguage = (typeof unicornProductPageLanguages)[number];

export const unicornOpportunityPageLanguages = ['de', 'en', 'es', 'it', 'mx', 'sv'] as const;
export type UnicornOpportunityPageLanguage = (typeof unicornOpportunityPageLanguages)[number];

export const unicornSolutionPageLanguages = ['us', 'es', 'it', 'de', 'mx', 'uk', 'gb', 'sv'] as const;
export type UnicornSolutionPageLanguage = (typeof unicornSolutionPageLanguages)[number];

export const landbotLinkLanguagesArray = ['us', 'de', 'es', 'uk'] as const;
export type LandbotLinkLanguage = (typeof landbotLinkLanguagesArray)[number];

export const AccountLanguageArray = ['en', 'de', 'es', 'it', 'sv', 'nl', 'mx', 'fr', 'pt', 'ja', 'ro'] as const;
export type AccountLanguage = (typeof AccountLanguageArray)[number];

export const LATIN_AMERICAN_REGION_CODE = '419';

export const EN_LANG = 'en';
export const DEFAULT_LANGUAGE = EN_LANG;

export interface LanguageHandlerObject<T = AccountLanguage | LandbotLinkLanguage> {
  icon: string;
  label: string;
  value: T;
}
export interface LanguageDTO<T> {
  abbreviation: T;
  createdAt: Date;
  i18nLabel: string;
  icon: string;
  isAccountLanguage: boolean;
  isLinkLanguage: boolean;
  language: string;
  updatedAt: Date;
}

const accountLanguages = ref<LanguageHandlerObject<AccountLanguage>[]>([]);
const linkLanguages = ref<LanguageHandlerObject<LandbotLinkLanguage>[]>([]);

export const useLanguageService = () => {
  const { getHeaders } = useAxiosUtils();
  const userStore = useUserStore();
  const { getTenantId } = useTenantService();

  const loadAccountLanguages = async (tenantId?: number) => {
    const query = qs.stringify({
      filters: {
        tenant: tenantId || getTenantId(),
        isAccountLanguage: true,
      },
    });
    const resp = await http.get<StrapiResponseDTO<StrapiDTO<LanguageDTO<AccountLanguage>>[]>>(`/api/languages?${query}`, getHeaders());
    accountLanguages.value = [];
    resp.data.data.map(lang => {
      accountLanguages.value.push({ icon: lang.attributes.icon, label: lang.attributes.i18nLabel, value: lang.attributes.abbreviation });
    });
    return accountLanguages.value;
  };

  const loadLinkLanguages = async () => {
    const query = qs.stringify({
      filters: {
        tenant: getTenantId(),
        isLinkLanguage: true,
      },
    });
    const resp = await http.get<StrapiResponseDTO<StrapiDTO<LanguageDTO<LandbotLinkLanguage>>[]>>(`/api/languages?${query}`, getHeaders());
    linkLanguages.value = [];
    resp.data.data.map(lang => {
      linkLanguages.value.push({ icon: lang.attributes.icon, label: lang.attributes.i18nLabel, value: lang.attributes.abbreviation });
    });
  };

  const accountLanguageBasedOnAvailableHelpPageLanguage = computed(() => {
    const availableHelpPageLanguages = ['en', 'es', 'de', 'it'];
    const accountLanguage =
      userStore.loggedInInfo?.user?.accountLanguage === 'mx' ? 'es' : userStore.loggedInInfo?.user?.accountLanguage || DEFAULT_LANGUAGE;
    return availableHelpPageLanguages.includes(accountLanguage) ? accountLanguage : EN_LANG;
  });

  const isUnicornProductPageLanguage = (language: string): language is (typeof unicornProductPageLanguages)[number] => {
    const allowedLanguages: readonly string[] = unicornProductPageLanguages;
    return allowedLanguages.includes(language);
  };
  const isUnicornOpportunityPageLanguage = (language: string): language is (typeof unicornOpportunityPageLanguages)[number] => {
    const allowedLanguages: readonly string[] = unicornOpportunityPageLanguages;
    return allowedLanguages.includes(language);
  };

  const isUnicornSolutionPageLanguage = (language: string): language is (typeof unicornSolutionPageLanguages)[number] => {
    const allowedLanguages: readonly string[] = unicornSolutionPageLanguages;
    return allowedLanguages.includes(language);
  };

  return {
    accountLanguages,
    linkLanguages,
    AccountLanguageArray,
    accountLanguageBasedOnAvailableHelpPageLanguage,
    loadAccountLanguages,
    loadLinkLanguages,
    isUnicornProductPageLanguage,
    isUnicornOpportunityPageLanguage,
    isUnicornSolutionPageLanguage,
  };
};
